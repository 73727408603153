import { Link } from "react-router-dom";

export const NavigateToStatusPage = () => {
  return (
    <>
      <div className="text-4xl mt-10">
        Your proposal was already submitted. Navigate to the{" "}
        <Link to="/status">status page</Link> to check on your approvals.
      </div>
    </>
  );
};

export default NavigateToStatusPage;
