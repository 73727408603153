import { concat, find } from "lodash";
import {
  auth,
  db,
  functions,
  googleProvider,
  storage,
  uploadBytes,
  ref,
  getDownloadURL,
} from "../../firebase.config.js";
import { httpsCallable } from "firebase/functions";
import { signInWithPopup } from "firebase/auth";
import { signInWithRedirect } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";

import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";

import { gunzip } from "fflate";
import { maxBy } from "lodash";
import { toast } from "react-toastify";
//import FileSaver from "file-saver";
//import { Link } from "react-router-dom";
// @ts-ignore
//import { toBytes } from "fast-base64";
//import { saveAs } from "file-saver";

import {
  apiUrlEducAllCourses,
  apiUrlSingularCourseLookupPrefix,
  baseEmailDomain,
  default215text,
  default180text,
  default190text,
  inverseProgramProgramAcronym,
  mappingGradingOption,
  mappingProgramDirector,
  mappingProgramRequirement,
  programProgramAcronym,
} from "./constant_values";

import {
  quarterlyUnitLimits,
  QuarterlyUnitLimits,
} from "./constant_unit_validation_requirements";

import { Course, YearQuarterRow } from "./interfaces";
import { ProgramAcronym } from "./types";

// technical debt: lookout for iepa ice and programrequirements blank out
export const blankOutCourses = (
  rowIndex: number,
  courseIndex: number,
  setFieldValue: any,
  setProgramRequirement: any,
) => {
  setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].courseTitle`, "");
  setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].unitsMin`, 1);
  setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].unitsMax`, 1);
  setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].courseUnits`, 0);

  const gradingOptionSelectArray: string[] = [];
  //setGradingOption(gradingOptionSelectArray);
  setFieldValue(
    `rows[${rowIndex}].courses[${courseIndex}].availableGradingOptions`,
    gradingOptionSelectArray,
  );
  setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].gradingOption`, "");

  const programRequirementSelectArray: string[] = [];
  setProgramRequirement(programRequirementSelectArray);
  setFieldValue(
    `rows[${rowIndex}].courses[${courseIndex}].programRequirement`,
    "",
  );
};

export const convertEpochToPST = (epochTime: number): string => {
  const losAngelesTime = toZonedTime(
    new Date(epochTime * 1000),
    "America/Los_Angeles",
  );
  const formattedTime = format(losAngelesTime, "yyyy-MM-dd HH:mm", {
    timeZone: "America/Los_Angeles",
  });

  return formattedTime;
};

export const toPacificTimeZone = (
  created: any,
  stringFormatting: string,
): string => {
  const createdDate = parseISO(created);
  const zonedTime = toZonedTime(createdDate, "America/Los_Angeles");
  //return format(zonedTime, "yyyy-MM-dd HH:mm", {
  // yyyy-MM-dd HH:mm classic print, what it says on the tin can
  // MMMM d, yyyy h:mmaaa pretty print,  July 3, 2023 4:32pm
  return format(zonedTime, stringFormatting, {
    timeZone: "America/Los_Angeles",
  });
};

export const currentRoundName = (
  individualSmartsheetSubmission: any,
  program: string,
) => {
  const current_submission_round =
    individualSmartsheetSubmission.submission_round;
  let proposalTypeName =
    program === "eds" || program === "hcp"
      ? [
          "1st Round Proposal",
          "2nd Round Proposal",
          "3rd Round Proposal",
          "4th Round Proposal",
        ][current_submission_round - 1]
      : ["Preliminary Proposal", "Final Proposal"][
          current_submission_round - 1
        ];
  return proposalTypeName;

  //if (mostRecentSubmission && mostRecentSubmission.submission_round === 1) {
  //  return "Preliminary Proposal";
  //} else if (
  //  mostRecentSubmission &&
  //  mostRecentSubmission.submission_round === 2
  //) {
  //  return "Final Proposal";
  //} else if (mostRecentSubmission) {
  //  return `Round ${mostRecentSubmission.submission_round} Proposal`;
  //}
};

export const determineCourseJsonResponse = (
  data: any,
  values: any,
  rowIndex: number,
  courseIndex: number,
  setFieldValue: any,
  setProgramRequirement: any,
  initialValues: any,
  searchParameterException: boolean = false,
) => {
  const course_valid = data.course_valid;
  const course_offered = data.course_offered;
  if (course_valid === false || course_offered === false) {
    blankOutCourses(
      rowIndex,
      courseIndex,
      setFieldValue,
      setProgramRequirement,
    );
    if (course_valid === false) {
      toast.error("Course number is invalid");
      throw new Error("Course number is invalid");
    } else if (course_offered === false) {
      toast.error("Course is not offered this academic year.");
      throw new Error("Course is not offered this academic year.");
    }
  } else if (course_valid && course_offered) {
    const updatedTermArray: string[] = data.term;
    //const updatedTermArray = term.map((str) => str.replace("Autumn", "Fall")); , now using Autumn instead of Fall, it's more official
    let quarter = values.rows[rowIndex].quarter;
    const year = values.rows[rowIndex].year;

    const keyQuarterYear: string[] = [quarter, year];
    const bothQuarterYearPresent: boolean = keyQuarterYear.every(
      (word: string) =>
        updatedTermArray.some((element: string) => element.includes(word)),
    );
    if (!searchParameterException) {
      if (!bothQuarterYearPresent) {
        let message = "Course is ";
        if (updatedTermArray.length === 1) {
          message += `only offered in ${updatedTermArray[0]}`;
        } else if (updatedTermArray.length === 2) {
          message += `offered in ${updatedTermArray.join(" and ")}`;
        } else {
          message += `offered in ${updatedTermArray
            .slice(0, -1)
            .join(", ")}, and ${updatedTermArray.slice(-1)}`;
        }
        blankOutCourses(
          rowIndex,
          courseIndex,
          setFieldValue,
          setProgramRequirement,
        );
        toast.error(message);
        throw new Error(message);
      }
    }
    const code = data.code;
    const subject = data.subject;
    const title = data.title;
    const grading = data.grading;
    const unitsMin = data.unitsMin;
    const unitsMax = data.unitsMax;
    if (grading in mappingGradingOption) {
      const gradingOptionSelectArray =
        mappingGradingOption[grading as keyof typeof mappingGradingOption];
      setFieldValue(
        `rows[${rowIndex}].courses[${courseIndex}].availableGradingOptions`,
        gradingOptionSelectArray,
      );
      //don't default to select first array value
      //setFieldValue(
      //  `rows[${rowIndex}].courses[${courseIndex}].gradingOption`,
      //  gradingOptionSelectArray[0],
      //);
    }
    // coming in from the gse-mais-live server student metadata
    const programAcronym = initialValues?.program ? initialValues.program : "";
    if (programAcronym) {
      const programRequirementSelectArray =
        mappingProgramRequirement[programAcronym as ProgramAcronym];
      setProgramRequirement(programRequirementSelectArray);
      //setFieldValue(`rows.${index}.programRequirement`, programRequirementSelectArray[0]);
      //don't default to select first array value
      //setFieldValue(
      //  `rows[${rowIndex}].courses[${courseIndex}].programRequirement`,
      //  programRequirementSelectArray[0],
      //);
    }
    let courseNumber = subject + code;
    if (excludeCourseUnits(courseNumber)) {
      // https://ed.stanford.edu/academics/doctoral-handbook/courses/overview
      toast.warning(
        `${courseNumber} is not included in unit calculations. Please refer to the Master’s Handbook.`,
      );
    }

    const validCodesManualInput = ["215", "180", "190"];
    if (subject === "EDUC" && validCodesManualInput.includes(code)) {
      handleCourseTitleUpdateManually(
        code,
        subject,
        rowIndex,
        courseIndex,
        values,
        setFieldValue,
      );
    } else {
      setFieldValue(
        `rows[${rowIndex}].courses[${courseIndex}].courseTitle`,
        title,
      );
    }

    setFieldValue(
      `rows[${rowIndex}].courses[${courseIndex}].unitsMin`,
      unitsMin,
    );
    setFieldValue(
      `rows[${rowIndex}].courses[${courseIndex}].unitsMax`,
      unitsMax,
    );
    setFieldValue(
      `rows[${rowIndex}].courses[${courseIndex}].courseUnits`,
      unitsMin,
    );
  }
};

//year: number,
export const emptyRowGenerator = (
  year: string,
  quarter: string,
  numCourses: number,
  program: string,
): YearQuarterRow => {
  //console.log("program in empty row");
  //console.log(program);

  let standardRowItems: any = {
    courseNumber: "",
    courseTitle: "",
    gradingOption: "",
    courseUnits: 0,
  };

  if (programDifferentiatorHasProgramRequirement(program)) {
    standardRowItems["programRequirement"] = "";
  }
  if (programDifferentiatorHasCrossListed(program)) {
    standardRowItems["crossListed"] = false;
  }

  const emptyCourses: Course[] = Array(numCourses).fill(standardRowItems);

  return {
    year: year,
    quarter: quarter,
    courses: emptyCourses,
    total: 0,
  };
};

export const excludeCourseUnits = (courseNumber: string): boolean => {
  const numberOfCourse = extractNumbers(courseNumber);
  if (numberOfCourse && numberOfCourse < 100) {
    return true;
  }

  return (
    courseNumber.includes("ESOLLANG") ||
    courseNumber.includes("EFSLANG") ||
    courseNumber.includes("ATHLETIC") ||
    courseNumber.includes("PHYSWELL")
  );
};

const extractNumbers = (str: string) => {
  const matches = str.match(/\d+/g);
  return matches ? matches.map(Number)[0] : undefined;
};

export const fetchCourseData = async (
  values: any,
  rowIndex: number,
  courseIndex: number,
  setFieldValue: any,
  setProgramRequirement: any,
  initialValues: any,
  beyondExploreCourses: any,
  constantValues: any,
) => {
  const currentValidExploreCoursesYear =
    constantValues.courseAccessLastQuarterYear.split(" ")[1];
  try {
    toast.dismiss();
    const courseNumber =
      values.rows[rowIndex].courses[courseIndex].courseNumber;
    const yearMMMMdashmmmm = values.rows[rowIndex]["year"];

    const transformedCourseNumber = courseNumber
      .replace(/\s+/g, "")
      .toUpperCase(); // Remove spaces and uppercase all characters
    if (transformedCourseNumber.length === 0) {
      console.log(
        "all safe, exit the try block, exit function, it's just an empty courseNumber input text cell",
      );
      return;
    }
    if (!/^[a-zA-Z]/.test(transformedCourseNumber)) {
      // Check if transformedCourseNumber starts with an alphabetical character
      blankOutCourses(
        rowIndex,
        courseIndex,
        setFieldValue,
        setProgramRequirement,
      );
      toast.error(
        "Course number must start with the subject prefix (e.g. EDUC, CS, BIOE, etc)",
      );
      throw new Error(
        "Course number must start with the subject prefix (e.g. EDUC, CS, BIOE, etc)",
      );
    } else if (!/[0-9]/.test(transformedCourseNumber.substring(1))) {
      blankOutCourses(
        rowIndex,
        courseIndex,
        setFieldValue,
        setProgramRequirement,
      );
      toast.error("Please enter full course number (e.g., EDUC 100A)");
      throw new Error("Please enter full course number (e.g., EDUC 100A)");
    }
    // logic,
    // if not beyond explorecourses and its an educ course and its the current explorecourses year
    //   do catched results
    // if it is beyond explore courses
    //   do a direct api lookup with the academic year
    //
    // if (transformedCourseNumber === "EDUC") {
    //     do some error handling
    // else
    // do a direct hit of the xml -> json api
    // because "The text does not start with 'EDUC' or is a different academic year",
    //

    if (
      !beyondExploreCourses &&
      transformedCourseNumber.startsWith("EDUC") &&
      transformedCourseNumber.length > 4 &&
      yearMMMMdashmmmm === currentValidExploreCoursesYear
      //yearMMMMdashmmmm === (await getConstantValues("defaultYear"))
    ) {
      const charactersAfterEDUC = transformedCourseNumber.substring(4);
      const cachedData = JSON.parse(
        localStorage.getItem(apiUrlEducAllCourses) as string,
      );
      const course_dictionary_list = cachedData.course_dictionary_list;
      const all_legit_codes = course_dictionary_list.map(
        (course: any) => course["code"],
      );
      if (!all_legit_codes.includes(charactersAfterEDUC)) {
        // possibly the EDUC ${somecode} could have existed in a prior year
        const response = await fetch(
          `${apiUrlSingularCourseLookupPrefix}${transformedCourseNumber}`,
        );
        const data = await response.json();
        determineCourseJsonResponse(
          data,
          values,
          rowIndex,
          courseIndex,
          setFieldValue,
          setProgramRequirement,
          initialValues,
        );
      } else {
        const course_of_interest_json = course_dictionary_list.filter(
          (course: any) => course.code === charactersAfterEDUC,
        )[0];
        determineCourseJsonResponse(
          course_of_interest_json,
          values,
          rowIndex,
          courseIndex,
          setFieldValue,
          setProgramRequirement,
          initialValues,
        );
      }
    } else if (beyondExploreCourses) {
      const oneYearPriorValidExploreCoursesYear = adjustYearRange(
        currentValidExploreCoursesYear,
        1,
      );
      const academicYearRangeForLookup =
        oneYearPriorValidExploreCoursesYear.replace("-", "");

      console.log(
        `${apiUrlSingularCourseLookupPrefix}${transformedCourseNumber}?academicYear=${academicYearRangeForLookup}`,
      );
      const response = await fetch(
        `${apiUrlSingularCourseLookupPrefix}${transformedCourseNumber}?academicYear=${academicYearRangeForLookup}`,
      );
      const data = await response.json();
      determineCourseJsonResponse(
        data,
        values,
        rowIndex,
        courseIndex,
        setFieldValue,
        setProgramRequirement,
        initialValues,
        true,
      );
    } else if (transformedCourseNumber === "EDUC") {
      blankOutCourses(
        rowIndex,
        courseIndex,
        setFieldValue,
        setProgramRequirement,
      );
      toast.error("Please enter full course number (e.g., EDUC 100A)");
      throw new Error("Please enter full course number (e.g., EDUC 100A)");
    } else {
      const yearMMMMmmmm = yearMMMMdashmmmm.replace(/-/g, "");
      console.log(
        "The text does not start with 'EDUC' or is a different academic year",
      );
      const response = await fetch(
        `${apiUrlSingularCourseLookupPrefix}${transformedCourseNumber}?academicYear=${yearMMMMmmmm}`,
      );
      const data = await response.json();
      determineCourseJsonResponse(
        data,
        values,
        rowIndex,
        courseIndex,
        setFieldValue,
        setProgramRequirement,
        initialValues,
      );
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
      //toast.error("Error fetching course data");
      //console.error("Error fetching course data:", error);
    } else {
      console.error("An unknown error occurred");
    }
  }
};

export const fetchGzipJSON = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      // Handle non-successful response
      throw new Error("Error occurred during fetch");
    }

    const gzippedData = await response.arrayBuffer();
    const gzippedBuffer = new Uint8Array(gzippedData);

    const decompressedData = await new Promise<string>((resolve, reject) => {
      gunzip(gzippedBuffer, (err, decompressedBuffer) => {
        if (err) {
          reject(err);
        } else {
          const decodedData = new TextDecoder().decode(decompressedBuffer);
          resolve(decodedData);
        }
      });
    });

    const jsonObject = JSON.parse(decompressedData);
    return jsonObject;
  } catch (error) {
    // Handle fetch or decompression error
    console.error(error);
    return {};
  }
};

export const determineFormVisibility = async (
  studentEmail: string,
  id_form_submission_lookup: string,
  setFormIntakeVisible: any,
) => {
  const singularFormSubmission = await getStudentFormSubmissionSingularItem(
    studentEmail,
    id_form_submission_lookup,
  );
  const is_submitted_smartsheet =
    singularFormSubmission.is_submitted_smartsheet;
  const is_rejected_smartsheet = singularFormSubmission.is_rejected_smartsheet;

  let message = "show visible form";
  if (!is_submitted_smartsheet) {
    setFormIntakeVisible(true);
  } else if (is_submitted_smartsheet && is_rejected_smartsheet) {
    setFormIntakeVisible(true);
  } else if (is_submitted_smartsheet && !is_rejected_smartsheet) {
    setFormIntakeVisible(false);
    message = "do not show form";
  }

  return message;
};

// TODO: legacy function, deprecated
export const fetchSmartsheetRelated = async (
  studentEmail: string,
  programAcronym: string,
  setSmartsheetResponse: any,
  setFormIntakeVisible: any,
  userdataPersonal: any,
) => {
  try {
    // Fetch smartsheet data
    const fetchData = httpsCallable(
      functions,
      "firebase_retrieve_saved_proposal_smartsheet",
    );

    const result = await fetchData({
      student_email: studentEmail,
      program_acronym: programAcronym,
    });

    const data = result.data as any;
    const fetchedSmartsheetData = data;
    setSmartsheetResponse(fetchedSmartsheetData);

    if (fetchedSmartsheetData) {
      let message = "";
      if (fetchedSmartsheetData.length === 0) {
        setFormIntakeVisible(true);
        message = "show visible form";
      } else {
        console.log("fetchedSmartsheetData");
        console.log(fetchedSmartsheetData);
        const newestSmartsheetRow = maxBy(
          fetchedSmartsheetData,
          "created",
        ) as any;

        const newestSmartsheetSubmissionRound =
          newestSmartsheetRow?.submission_round;

        //const newestSmartsheetPpmaId = newestSmartsheetRow?.ppma_id;

        const newestSmartsheetHasDeclinedValue = Object.values(
          newestSmartsheetRow,
        ).some((value) => value === "Revise & Resubmit");
        const currentSubmissionRound = userdataPersonal?.submission_round;
        if (
          newestSmartsheetHasDeclinedValue ||
          (currentSubmissionRound !== undefined &&
            currentSubmissionRound > newestSmartsheetSubmissionRound)
        ) {
          setFormIntakeVisible(true);
          message = "show visible form";
        } else {
          setFormIntakeVisible(false);
          message = "do not show form";
        }
      }
      console.log(message);
      return message;
    }
  } catch (error) {
    console.error("Error fetching smartsheet data:", error);
    throw error;
  }
};

export const fetchSmartsheetRelatedOnly = async (
  studentEmail: string,
  programAcronym: string,
  setSmartsheetResponse: any,
) => {
  try {
    const fetchData = httpsCallable(
      functions,
      "firebase_retrieve_saved_proposal_smartsheet",
    );
    const result = await fetchData({
      student_email: studentEmail,
      program_acronym: programAcronym,
    });
    const data = result.data;
    const fetchedSmartsheetData = data;
    setSmartsheetResponse(fetchedSmartsheetData);
  } catch (error) {
    console.error("Error fetching smartsheet data:", error);
    throw error;
  }
};

export const fetchUserdataPersonalOnly = async (
  setUserdataPersonal: any,
  userData: any,
) => {
  try {
    // forces a hold until userData is completely populated
    while (!userData.username || !userData.displayName) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // wait for 100ms before checking again
    }
    const uid = userData.username;
    const studentEmail = `${uid}@${baseEmailDomain}`;
    const data_response_retrieve_proposal_setup_from_db =
      await getStudentMetadata(studentEmail);
    if (
      !(
        "univid" in data_response_retrieve_proposal_setup_from_db &&
        data_response_retrieve_proposal_setup_from_db.univid
      )
    ) {
      throw new Error(
        "No previous student_metadata saved. Account needs to be initialized properly.",
      );
    }

    setUserdataPersonal(data_response_retrieve_proposal_setup_from_db);
    //return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserdataPersonalAndInitialValues = async (
  setUserdataPersonal: any,
  setInitialValues: any,
  userData: any,
) => {
  try {
    while (!userData.username || !userData.displayName) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // wait for 100ms before checking again
    }

    const uid = userData.username;
    const displayName = userData.displayName;
    const studentEmail = `${uid}@${baseEmailDomain}`;
    const data_response_retrieve_proposal_setup_from_db =
      await getStudentMetadata(studentEmail);
    if (
      !(
        "univid" in data_response_retrieve_proposal_setup_from_db &&
        data_response_retrieve_proposal_setup_from_db.univid
      )
    ) {
      throw new Error(
        "No previous student_metadata saved. Account needs to be initialized properly.",
      );
    }
    setUserdataPersonal(data_response_retrieve_proposal_setup_from_db);

    const submission_round =
      data_response_retrieve_proposal_setup_from_db.submission_round;

    //advisor_sunet_list
    const excludedSunetIds = [
      ...new Set(
        Object.values(mappingProgramDirector)
          .map((email) => email.split("@")[0]) // Get the part before the '@'
          .filter((email) => email), // Filter out empty strings
      ),
    ];
    const advisor_email_verbose =
      data_response_retrieve_proposal_setup_from_db.advisorSunetid
        .split(",")
        .filter((x: string) => !excludedSunetIds.includes(x))
        .map((x: string) => `${x}@stanford.edu`)
        .join(", ");
    // data that is set in the html

    const programAcronym = programAcronymFromDataFullProgram(
      data_response_retrieve_proposal_setup_from_db,
    );
    const director_email = programAcronym
      ? mappingProgramDirector[programAcronym as ProgramAcronym]
      : "";

    const svalues = {
      is_unique_schedule:
        data_response_retrieve_proposal_setup_from_db.is_unique_schedule,
      program: programAcronym,
      year_quarter_setup:
        data_response_retrieve_proposal_setup_from_db.year_quarter_setup,
      year_quarter_setup_original:
        data_response_retrieve_proposal_setup_from_db.year_quarter_setup_original,
    };
    // spoof the values with the program and is_unique_schedule, method signature currently required in initializeMeta

    const checkboxes_instantiated = initializeMeta(svalues);
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      name: displayName,
      student_id: data_response_retrieve_proposal_setup_from_db.univid,
      student_email: studentEmail,
      advisor_email: advisor_email_verbose,
      director_email: director_email,
      program: programAcronym,
      total_units: 0,
      plan: "",
      checkboxes: checkboxes_instantiated,
      submission_round: submission_round,
    }));
    //return data;
  } catch (error) {
    console.log(error);
  }
};

// deprecated no longer used function
//export const generateNumberRange = (startNumber: number): number[] => {
//  //const endNumber = 12;
//  const endNumber = 36;
//  const count = endNumber - startNumber + 1;
//  return Array.from({ length: count }, (_, index) => startNumber + index);
//};

export const generateValidationInfoMessage = (values: any) => {
  let validation_message_array: string[] = [];
  const springQuarter = find(values.rows, { quarter: "Spring" });
  const springTotalUnits = springQuarter?.total;

  if (springTotalUnits && values.program === "ldt" && springTotalUnits > 10) {
    validation_message_array.push(
      `Ideally the quarter units for spring should be between 8-10 units, so as to not incur a tuition cost increase. The Spring quarter proposal listing currently has ${springTotalUnits} units.`,
    );
  }
  return validation_message_array;
};

export const generateValidationMessage = (
  values: any,
  smartsheetOverridesProgramLastQuarterRelevantIndividual: any,
) => {
  // get the program and get the start year
  let validation_message_array: string[] = [];

  // TODO: work around and make this cleaner, no advisor programs.... logic for that
  if (values.advisor_email.includes(",") && values.program !== "eds") {
    validation_message_array.push(
      "You have multiple advisors that can be used for an approval. Please select one.",
    );
  }

  if (values.attestation.length < 2) {
    validation_message_array.push(
      "Please enter your initials to acknowledge that you have reviewed your program proposal and that it satisfies all program requirements.",
    );
  }
  // TODO: code for manually checking if I remove the yup validations
  //if (values.phone.length < 2) {
  //  validation_message_array.push(`Please enter your phone number.`);
  //}
  //const rowsWithEmptyOrShortTitle = values.rows.filter((row: any) =>
  //  row.courses.some(
  //    (course: any) => !course.courseTitle || course.courseTitle.length < 2,
  //  ),
  //);
  // not needed anymore, no more free form entry
  // const coursesWithEmptyOrShortTitle = values.rows.flatMap((row: any) =>
  //   row.courses
  //     .filter(
  //       (course: any) =>
  //         !course.courseTitle ||
  //         course.courseTitle.length < 2 ||
  //         course.courseUnits === 0,
  //     )
  //     .map((course: any) => ({
  //       ...course,
  //       quarter: row.quarter,
  //       year: row.year,
  //     })),
  // );

  // for (const course of coursesWithEmptyOrShortTitle) {
  //   // slight TODO: can this be optimized to be included with the prior body of code?
  //   //console.log("an individual course");
  //   //console.log(course);
  //   const courseTitle = course.courseTitle;
  //   const courseNumber = course.courseNumber;
  //   const programRequirement = course.programRequirement;
  //   const gradingOption = course.gradingOption;
  //   if (courseNumber.length > 0) {
  //     if (courseTitle.length < 1) {
  //       validation_message_array.push(
  //         `Please type in the course title for the ${courseNumber}, and fill out the rest of the course details.`,
  //       );
  //     } else if (gradingOption.length < 1) {
  //       validation_message_array.push(
  //         `Please adjust the Grading Option for ${courseNumber} the value did not get established yet.`,
  //       );
  //     } else if (programRequirement.length < 1) {
  //       validation_message_array.push(
  //         `Please adjust the Program Requirements for ${courseNumber} the value did not get established yet.`,
  //       );
  //     }
  //   }
  // }

  const rows = values.rows;
  rows.forEach((row: any) => {
    const courseNumbers: string[] = row.courses.map(
      (course: any) => course.courseNumber,
    );
    const duplicates = courseNumbers.filter(
      (courseNumber, index) => courseNumbers.indexOf(courseNumber) !== index,
    );

    if (duplicates.length > 0) {
      const duplicatesMessage = `${row.quarter} ${
        row.year
      } has duplicate course work listed: ${duplicates.join(
        ", ",
      )}. You may only list a unique course once per quarter.`;
      validation_message_array.push(duplicatesMessage);
    }
  });

  const more_validation_messages = checkMetaRules(
    values,
    smartsheetOverridesProgramLastQuarterRelevantIndividual,
  ).more_validation_messages;
  validation_message_array = [
    ...validation_message_array,
    ...more_validation_messages,
  ];

  return validation_message_array;
};

export const generateYearQuarterDataList = (
  years: any,
  quarters: any,
  program: string,
) => {
  const standardYearQuarterDataList = years.flatMap((year: any) => {
    return quarters.map((quarter: any) => ({
      year,
      quarter,
    }));
  });
  if (program !== "joint_ma_mba") {
    return standardYearQuarterDataList;
  } else {
    const lastYear = years[years.length - 1];
    const lastStartYear = parseInt(lastYear.slice(0, 4), 10);

    const startYear = lastStartYear + 1;
    const endYear = startYear + 1;
    const newYearRange = `${startYear}-${endYear}`;
    const newAdditionalInstanceYearQuarterObject = {
      year: newYearRange,
      quarter: quarters[0],
    };
    const newStandardYearQuarterDataList = [
      ...standardYearQuarterDataList,
      newAdditionalInstanceYearQuarterObject,
    ];

    return newStandardYearQuarterDataList;
  }
};

// no longer used, deprecated...
export const generateYearQuarterRows = (
  startingYear: string,
  startingQuarter: string,
  numberOfQuarters: number,
  program: string,
): YearQuarterRow[] => {
  const all_rows: YearQuarterRow[] = [];

  const defaultNumCourses: number = 1;
  const quartersSetup = getQuartersSetup(program);
  const endOfSchoolYearQuarter = quartersSetup[quartersSetup.length - 1];
  //const numberOfQuarters = getNumberOfQuarters(program);

  let currentYear: string = startingYear;
  let currentQuarterIndex: number = quartersSetup.indexOf(startingQuarter);

  for (let i: number = 0; i < numberOfQuarters; i++) {
    const quarter: string = quartersSetup[currentQuarterIndex];
    all_rows.push(
      emptyRowGenerator(currentYear, quarter, defaultNumCourses, program),
    );

    if (quarter === endOfSchoolYearQuarter) {
      currentYear = incrementYearRange(currentYear);
    }
    currentQuarterIndex = (currentQuarterIndex + 1) % quartersSetup.length;
  }

  return all_rows;
};

export const getBeginningYear = (yearValue: string): number => {
  const beginningYear = parseInt(yearValue.split("-")[0], 10);
  return beginningYear;
};

// get me Pacific Time
function getCurrentLosAngelesTime(): string {
  const losAngelesTimeZone = "America/Los_Angeles";
  const now = new Date();
  const losAngelesTime = toZonedTime(now, losAngelesTimeZone);
  return format(losAngelesTime, "yyyyMMdd-HHmm");
}

const getEmailPrefix = (email: string): string => {
  const parts = email.split("@");
  if (parts.length === 2) {
    return parts[0];
  } else {
    return "";
  }
};

// established in the python backend now
//export const getNumberOfQuarters = (program: string): number => {
//  let numberOfQuarters: number = 4; // Default number of quarters
//
//  if (program === "hcp") {
//    numberOfQuarters = 8;
//  } else if (program === "eds") {
//    numberOfQuarters = 7;
//  } else if (program === "pols") {
//    numberOfQuarters = 3;
//  } else if (program === "mpp") {
//    numberOfQuarters = 6;
//  }
//
//  return numberOfQuarters;
//};

export const getLatestSubmission = async (studentEmail: any) => {
  try {
    const collectionName = "form_submission";
    //const subcollectionPath = `${collectionName}/${studentEmail}/id_${collectionName}`;
    const subcollectionPath = `sunet_logins/${studentEmail}/${collectionName}`;

    //console.log("subcollectionPath:", subcollectionPath);
    // Create a query to get the latest document by timestamp
    const q = query(
      collection(db, subcollectionPath),
      orderBy("timestamp_epoch", "desc"),
      limit(1),
    );

    // Get the documents matching the query
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const submissionDict = latestDoc.data();
      //console.log("Current data:", submissionDict);
      // Return the submission data
      return submissionDict;
    } else {
      // No documents found
      return null;
    }
  } catch (error) {
    console.error("Error fetching the latest submission:", error);
    // Handle and rethrow the error with a custom message
    throw new Error(
      `Network response was not ok or no previous proposal saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getStudentMetadata = async (studentEmail: string) => {
  try {
    const uid = studentEmail.split("@")[0];
    const studentDocRef = doc(db, "sunet_logins", studentEmail);

    const studentDocSnapshot = await getDoc(studentDocRef);

    if (studentDocSnapshot.exists()) {
      const studentMetadata = studentDocSnapshot.data();

      const desiredStudentMetadata = {
        advisorSunetid: studentMetadata.advisorSunetid,
        program: studentMetadata.program,
        student_email: studentMetadata.student_email,
        submission_round: studentMetadata.submission_round,
        uid: uid,
        univid: studentMetadata.univid,
        year_quarter_setup: studentMetadata.year_quarter_setup,
        year_quarter_setup_original:
          studentMetadata.year_quarter_setup_original,
        is_unique_schedule: studentMetadata.is_unique_schedule,
      };

      return desiredStudentMetadata;
    } else {
      return { error: "Student program record not found" };
    }
  } catch (error) {
    console.error("Error fetching the student metadata:", error);
    throw new Error(
      `Network response was not ok or no previous student metadata saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getStudentFormSubmissionSingularItem = async (
  studentEmail: string,
  formSubmissionId: string,
) => {
  try {
    const studentDocRef = doc(db, "sunet_logins", studentEmail);
    const formSubmissionRef = doc(
      studentDocRef,
      "form_submission",
      formSubmissionId,
    );
    const formSubmissionDocSnapshot = await getDoc(formSubmissionRef);

    if (formSubmissionDocSnapshot.exists()) {
      // The form submission document exists, you can access its data here
      const formSubmissionData = formSubmissionDocSnapshot.data();
      return formSubmissionData;
    } else {
      console.log("No form submission document found.");
      return {};
    }
  } catch (error) {
    console.error("Error fetching the student form submission:", error);
    throw new Error(
      `Network response was not ok or no previous student form submission saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getStudentFormSubmissionAndSetSubmittedStatus = async (
  studentEmail: string,
  formSubmissionId: string,
  status: boolean,
) => {
  try {
    const studentDocRef = doc(db, "sunet_logins", studentEmail);
    const formSubmissionRef = doc(
      studentDocRef,
      "form_submission",
      formSubmissionId,
    );

    // Check if the document exists
    const formSubmissionDocSnapshot = await getDoc(formSubmissionRef);
    if (!formSubmissionDocSnapshot.exists()) {
      console.log("Document does not exist");
      return;
    }

    // Update the document directly
    await updateDoc(formSubmissionRef, { is_submitted_smartsheet: status });

    console.log("Form submission document updated successfully.");
  } catch (error) {
    console.error("Error updating the student form submission:", error);
    throw new Error(
      `Network response was not ok or no previous student form submission saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getConstantValues = async (key: string) => {
  try {
    const collectionName = "smartsheet_constant_values";
    const DocRef = doc(collection(db, collectionName), key);

    const DocRefSnapshot = await getDoc(DocRef);

    if (DocRefSnapshot.exists()) {
      const metadata = DocRefSnapshot.data();
      const value = metadata[key];
      return value;
    }
  } catch (error) {
    console.error("Error fetching the student metadata:", error);
    // Handle and rethrow the error with a custom message
    throw new Error(
      `Network response was not ok or no previous student metadata saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getOverrides = async (sunet_email: string) => {
  try {
    const collectionName = "smartsheet_overrides_program_last_quarter";
    const docRef = doc(db, collectionName, sunet_email);

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const metadata = docSnapshot.data();

      const override_last_quarter_units = metadata.override_last_quarter_units;
      const program = metadata.program;

      return { override_last_quarter_units, program };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching the student metadata:", error);
    throw new Error(
      `Network response was not ok or no previous student metadata saved. ${
        (error as Error).message
      }`,
    );
  }
};

export const getPriorYearRanges = (
  currentYearRange: string,
  defaultYear: string,
  program: string,
): string[] => {
  const currentStartYear = parseInt(currentYearRange.slice(0, 4), 10);
  const defaultStartYear = parseInt(defaultYear.slice(0, 4), 10);
  const priorYearRanges = [];
  //console.log("currentStartYear");
  //console.log(currentStartYear);
  //console.log(defaultStartYear);

  let numberOfYearsToExamine = 3;
  if (program === "hcp") {
    numberOfYearsToExamine = 7;
  }
  if (defaultStartYear !== currentStartYear) {
    // TODO: check this logic later for, math needs to be doublechecked
    for (
      let i = -numberOfYearsToExamine;
      i <= defaultStartYear - currentStartYear;
      i++
    ) {
      const startYear = currentStartYear + i;
      const endYear = startYear + 1;
      const yearRange = `${startYear}-${endYear}`;
      priorYearRanges.push(yearRange);
    }
  } else {
    // >= to >
    for (let i = numberOfYearsToExamine; i > 0; i--) {
      const startYear = currentStartYear - i;
      const endYear = startYear + 1;
      const yearRange = `${startYear}-${endYear}`;
      priorYearRanges.push(yearRange);
    }
  }

  return priorYearRanges;
};

export const getQuartersSetup = (program: string): string[] => {
  let quartersSetup: string[] = ["Autumn", "Winter", "Spring", "Summer"];
  if (["pols", "mpp"].includes(program)) {
    quartersSetup = ["Autumn", "Winter", "Spring"];
  }
  return quartersSetup;
};

export const getUsernameFromEmail = (email: string): string => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    return email.slice(0, atIndex);
  }
  return ""; // Return null if '@' symbol is not found in the email
};

// TODO: legacy function, wipe out, just modified in this commit because userdata -> userdataPersonal
export const handleGeneratePDF = async (
  values: any,
  setLoadingSmartsheet: any,
  setFormIntakeVisible: any,
  setSmartsheetResponse: any,
  userdataPersonal: any,
) => {
  try {
    const losAngelesTime: string = getCurrentLosAngelesTime();
    const student_email = values.student_email;
    const sunet = getEmailPrefix(student_email);
    const program_acronym = values.program;
    const program_dashed = program_acronym.replace(/_/g, "-");
    const custom_filename = `${sunet}-${program_dashed}-student-record-${losAngelesTime}.pdf`;
    const extraVariables = {
      losAngelesTime: losAngelesTime,
      sunet: sunet,
      program_dashed: program_dashed,
      custom_filename: custom_filename,
    };
    const senddata = { ...values, ...extraVariables };
    setLoadingSmartsheet(true);

    const fetchData = httpsCallable(
      functions,
      "firebase_save_proposal_form_pdf_deux",
    );
    const result = await fetchData(senddata);
    const data = result.data as any;
    console.log("data in fb call for pdf");
    console.log(data);
    const fetchedDataStudent = data[0];
    if (data[1] !== 200) {
      const error = fetchedDataStudent.error;
      throw new Error(
        `Failed to send data to the Firebase python backend. ${error}`,
      );
    }
    await fetchSmartsheetRelated(
      student_email,
      program_acronym,
      setSmartsheetResponse,
      setFormIntakeVisible,
      userdataPersonal,
    );
  } catch (error) {
    console.error(error);
    alert("Error generating PDF");
  } finally {
    setLoadingSmartsheet(false);
  }
};

export const handleGeneratePDFtrois = async (
  values: any,
  setUrlPdfFileStorage: any,
  setPdfFileName: any,
) => {
  try {
    const losAngelesTime: string = getCurrentLosAngelesTime();
    const student_email = values.student_email;
    const sunet = getEmailPrefix(student_email);
    const program_acronym = values.program;
    const program_dashed = program_acronym.replace(/_/g, "-");
    const custom_filename = `${sunet}-${program_dashed}-student-record-${losAngelesTime}.pdf`;
    const extraVariables = {
      losAngelesTime: losAngelesTime,
      sunet: sunet,
      program_dashed: program_dashed,
      custom_filename: custom_filename,
    };
    const senddata = { ...values, ...extraVariables };

    const fetchData = httpsCallable(
      functions,
      "firebase_save_proposal_form_pdf_trois",
    );
    const result = await fetchData(senddata);
    const data = result.data as any;
    console.log("data in fb call for pdf");
    console.log(data);
    const fetchedDataStudent = data[0];
    if (data[1] !== 200) {
      const error = fetchedDataStudent.error;
      throw new Error(
        `Failed to send data to the Firebase python backend. ${error}`,
      );
    } else {
      const valid_firestore_pdf_path = `${student_email}/${custom_filename}`;
      console.log(valid_firestore_pdf_path);
      getDownloadURL(ref(storage, valid_firestore_pdf_path))
        .then((url: any) => {
          // `url` is the download URL for the file that was uploaded to firebase storage
          setUrlPdfFileStorage(url);
          setPdfFileName(custom_filename);
          console.log("url");
          console.log(url);
        })
        .catch((error: any) => {
          // Handle any errors
          console.log("error");
          console.log(error);
        });
    }
  } catch (error) {
    console.error(error);
    toast.error("Error generating PDF");
  }
};

export const handleKeyDownCourseNumber = (
  e: React.KeyboardEvent<SVGSVGElement> | React.KeyboardEvent<HTMLInputElement>,
  values: any, // Replace 'any' with the type of 'values'
  rowIndex: number,
  courseIndex: number,
  setFieldValue: Function,
  setProgramRequirement: Function,
  initialValues: any, // Replace 'any' with the type of 'initialValues'
  beyondExploreCourses: any,
  constantValues: any,
) => {
  if (e.key === "Enter" || e.key === "Return") {
    fetchCourseData(
      values,
      rowIndex,
      courseIndex,
      setFieldValue,
      setProgramRequirement,
      initialValues,
      beyondExploreCourses,
      constantValues,
    );
  }
};

export const handleTextChangeCourseNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  rowIndex: number,
  courseIndex: number,
  setFieldValue: Function,
  setProgramRequirement: Function,
) => {
  let uppercasedNoSpacedValue = "";
  if (e.target.value.length === 0) {
    blankOutCourses(
      rowIndex,
      courseIndex,
      setFieldValue,
      setProgramRequirement,
    );
  } else {
    setFieldValue(`rows[${rowIndex}].courses[${courseIndex}].courseTitle`, "");
    uppercasedNoSpacedValue = e.target.value.toUpperCase().replace(/\s+/g, "");
  }
  setFieldValue(
    `rows[${rowIndex}].courses[${courseIndex}].courseNumber`,
    uppercasedNoSpacedValue,
  );
};

const incrementYearRange = (yearRange: string): string => {
  const [startYear, endYear] = yearRange.split("-");

  if (startYear && endYear) {
    const newStartYear = String(Number(startYear) + 1);
    const newEndYear = String(Number(endYear) + 1);
    return `${newStartYear}-${newEndYear}`;
  } else {
    throw new Error('Invalid year range format. Use "YYYY - YYYY".');
  }
};

export const insertRow = (
  rowIndex: number,
  courseIndex: number,
  newRow: any,
  values: any,
  setFieldValue: any,
  setInitialValuesLog: any,
) => {
  const updatedRows = [...values.rows];
  if (updatedRows[rowIndex] && updatedRows[rowIndex].courses) {
    updatedRows[rowIndex].courses.splice(courseIndex + 1, 0, newRow);
  }
  //newRows.splice(index + 1, 0, newRow);
  setFieldValue("rows", updatedRows);
  // Update the initialValuesLog with the new initialValues
  setInitialValuesLog({ ...values, rows: updatedRows });
};

export const programAcronymFromDataFullProgram = (data: any) => {
  return data.program
    ? programProgramAcronym[data.program as keyof typeof programProgramAcronym]
    : "designed";
};

export const programDifferentiatorHasCrossListed = (
  program: string,
): boolean => {
  return ["joint_ma_mba"].includes(program);
};

export const programDifferentiatorHasInternshipOption = (
  program: string,
): boolean => {
  return ["ldt", "hcp"].includes(program);
};

export const programDifferentiatorHasPlanAPlanB = (
  program: string,
): boolean => {
  return ["iepa", "ice"].includes(program);
};

export const programDifferentiatorHasProgramRequirement = (
  program: string,
): boolean => {
  return !["iepa", "ice", "joint_ma_mba", "designed"].includes(program);
};

export const programDifferentiatorNoAdvisor = (program: string): boolean => {
  return ["pols", "mpp", "joint_ma_mba", "eds", "ldt", "hcp"].includes(program);
};

export const programDifferentiatorNoCoTerm = (program: string): boolean => {
  return ["joint_ma_mba"].includes(program);
};

export const programDifferentiatorNoDirector = (program: string): boolean => {
  return ["designed"].includes(program);
};

export const removeQuarter = async (
  year: any,
  quarter: string,
  values: any,
  //setFieldValue: any,
  //setInitialValuesLog: any,
) => {
  console.log(year, quarter);
  const student_email = values.student_email;

  const fetchData = httpsCallable(
    functions,
    "firebase_unique_schedule_remove_quarter",
  );

  const result = await fetchData({
    student_email: student_email,
    year: year,
    quarter: quarter,
  });

  const data = result.data as any;
  console.log(data);

  // setFieldValue("rows", updatedRows);
  // Update the log with the new state
  // setInitialValuesLog({ ...values, rows: updatedRows });
};
export const removeRow = (
  rowIndex: number,
  courseIndex: number,
  values: any,
  setFieldValue: any,
  setInitialValuesLog: any,
) => {
  // Create a copy of current rows
  const updatedRows = [...values.rows];
  // Check if a course exists in the specific row and remove it
  if (updatedRows[rowIndex] && updatedRows[rowIndex].courses) {
    updatedRows[rowIndex].courses.splice(courseIndex, 1);
  }
  // If the last course in the row is removed, then remove the entire row
  // this should never occur though, because the removeRow button is no longer present in such scenario
  if (updatedRows[rowIndex] && updatedRows[rowIndex].courses.length === 0) {
    updatedRows.splice(rowIndex, 1);
  }
  // Update Formik's state with the new rows
  setFieldValue("rows", updatedRows);
  // Update the log with the new state
  setInitialValuesLog({ ...values, rows: updatedRows });
};

export const unitsLetterGraded = (program: string) => {
  // "65+ Letter graded units" // mpp
  // "27+ Letter graded units" // joint_ma_mba
  // "17+ Letter graded units" // eds
  // "16+ Letter graded units" // ice , iepa
  // "15+ Letter graded units" // pols, ldt, hcp, designed

  // default for pols, ldt, hcp, designed
  let unit_threshhold: number;
  switch (program) {
    case "mpp":
      unit_threshhold = 65;
      break;
    case "joint_ma_mba":
      unit_threshhold = 27;
      break;
    case "eds":
      unit_threshhold = 17;
      break;
    case "ice":
    case "iepa":
      unit_threshhold = 16;
      break;
    default:
      unit_threshhold = 15; // Default value for pols, ldt, hcp, designed
  }

  return `${unit_threshhold}+ Letter graded units`;
};

export const unitsAtOrAbove200 = (program: string) => {
  // "48+ units at or above the 200 level" mpp
  // "25+ units at or above the 200 level" eds
  // "24+ units at or above the 200 level" ice iepa
  // "23+ units at or above the 200 level" pols ldt hcp designed
  // "18+ units at or above the 200 level" joint_ma_mba
  let unit_threshhold: number = 23;
  switch (program) {
    case "mpp":
      unit_threshhold = 48;
      break;
    case "eds":
      unit_threshhold = 25;
      break;
    case "ice":
    case "iepa":
      unit_threshhold = 24;
      break;
    case "pols":
    case "ldt":
    case "hcp":
    case "designed":
      unit_threshhold = 23;
      break;
    case "joint_ma_mba":
      unit_threshhold = 18;
      break;
  }

  return `${unit_threshhold}+ units at or above the 200 level`;
};

export const unitsForEDUC = (program: string) => {
  // "27+ EDUC units" pols mpp designed
  // "30+ EDUC units" ldt hcp eds ice iepa
  let unit_threshhold: number = 27;
  switch (program) {
    case "pols":
    case "mpp":
    case "designed":
      unit_threshhold = 27;
      break;
    case "ldt":
    case "hcp":
    case "eds":
    case "ice":
    case "iepa":
      unit_threshhold = 30;
      break;
  }
  return `${unit_threshhold}+ EDUC units`;
};

export const unitsForProgramType = (program_type: string, values: any) => {
  let program_type_description: string = "MA"; // Default value

  const program = program_type as keyof QuarterlyUnitLimits;
  const quarterlyUnitLimitsForProgram = quarterlyUnitLimits[program];
  let cumulativeUnitsRequired =
    quarterlyUnitLimitsForProgram["cumulativeUnits"];

  if (
    program === "joint_ma_mba" &&
    values.year_quarter_setup_original[0]["year"] === "2023-2024"
  ) {
    cumulativeUnitsRequired = 35; // old default
  }

  switch (program_type) {
    case "designed":
    case "ice":
    case "iepa":
    case "joint_ma_mba":
    case "pols":
      program_type_description = "MA";
      break;
    case "eds":
    case "hcp":
    case "ldt":
      program_type_description = "MS";
      break;
    case "mpp":
      program_type_description = "MA/MPP";
      break;
  }

  return `${cumulativeUnitsRequired}+ units toward ${program_type_description}`;
};

// making the rules
// rules to make the checkmark text
//
// to be applied later in the calculations section
//
// have as input the program name
//

// deprecated method, unique schedule boolean stored on values now
//const isUniqueSchedule = (values: any): boolean => {
//  const scheduleWasAdjusted =
//    values.initializedYear !== values.startingYearOriginal ||
//    values.initializedQuarter !== values.startingQuarterOriginal ||
//    values.initializedNumberOfQuarters !== values.numberOfQuartersOriginal;
//  return scheduleWasAdjusted;
//};

export const RulesObject = {
  Rule1: "All courses at or above the 100 level",
  Rule2: "8-18 units Autumn/Winter/Spring",
  Rule3: "11-18 units each quarter",
  Rule4: "8-18 units Autumn/Winter/Spring; 6 Summer",
  //Rule5: "11/11/8/6 or 11/11/11/3",
  Rule5: "Plan A or Plan B selected",
  Rule6: "EDUC 393",
  Rule7: "10 cross-listed units max",
  Rule8: "4 independent study units max",
  Rule0: "Course Requirements",
  RuleGPA: "3.0 GPA",
};

const Rule1 = RulesObject.Rule1;
const Rule2 = RulesObject.Rule2;
const Rule3 = RulesObject.Rule3;
const Rule4 = RulesObject.Rule4;
const Rule5 = RulesObject.Rule5;
const Rule6 = RulesObject.Rule6;
const Rule7 = RulesObject.Rule7;
const Rule8 = RulesObject.Rule8;
//const Rule0 = RulesObject.Rule0;
const RuleGPA = RulesObject.RuleGPA;

export const initializeMeta = (values: any) => {
  //let meta = [];
  //let metaObject: { [key: string]: string | Function } = {};

  const program = values.program;
  let metaObject: { [key: string]: any } = {};

  if (program === "pols") {
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule3;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "ldt") {
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule4;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "hcp") {
    // six items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = unitsForEDUC(program);
    metaObject["checkbox5"] = unitsForProgramType(program, values);
    //metaObject["checkbox6"] = Rule0;
  } else if (program === "mpp") {
    // seven items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule2;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "eds") {
    // seven items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule2;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "ice") {
    // seven items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule5;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "iepa") {
    // seven items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = Rule5;
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = unitsForProgramType(program, values);
    //metaObject["checkbox7"] = Rule0;
  } else if (program === "joint_ma_mba") {
    // seven items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = Rule7;
    metaObject["checkbox3"] = Rule6;
    metaObject["checkbox4"] = unitsAtOrAbove200(program);
    metaObject["checkbox5"] = unitsLetterGraded(program);
    metaObject["checkbox6"] = Rule8;
    metaObject["checkbox7"] = unitsForProgramType(program, values);
  } else if (program === "designed") {
    // six items
    metaObject["checkbox1"] = Rule1;
    metaObject["checkbox2"] = unitsLetterGraded(program);
    metaObject["checkbox3"] = unitsAtOrAbove200(program);
    metaObject["checkbox4"] = unitsForProgramType(program, values);
    metaObject["checkbox5"] = unitsForEDUC(program);
    metaObject["checkbox6"] = RuleGPA; // how calculate?
  }
  // logic of if unique schedule, remove rule2 and rule3 and rule4 and rule5
  // increment the checkbox logics...renumber the checkboxes...
  if (values.is_unique_schedule) {
    // Filter out Rule2, Rule3, Rule4, and Rule5
    const filteredMetaObject = Object.entries(metaObject).filter(
      ([_, metatext]) => ![Rule2, Rule3, Rule4, Rule5].includes(metatext),
    );

    metaObject = {};
    filteredMetaObject.forEach(([_, metatext], index) => {
      metaObject[`checkbox${index + 1}`] = metatext;
    });
  }
  const transformedCheckboxes = Object.keys(metaObject).reduce(
    (acc: any, key) => {
      acc[key] = {
        label: metaObject[key],
        checked: false,
      };
      return acc;
    },
    {},
  );
  return transformedCheckboxes;
};

const extractUnits = (program: string): number => {
  const unitsMatch = program.match(/\d+/);
  if (unitsMatch && unitsMatch.length > 0) {
    return parseInt(unitsMatch[0], 10);
  }
  return 0;
};

// put in a method signatature overrides default {} because this function runs twice and outputs
// on first run tempcheckboxes wording isn't affected by override
// more validation messages is affected by overrides
export const checkMetaRules = (values: any, overrides: any = {}) => {
  let object_return = {} as any;
  let more_validation_messages: string[] = [];

  ///////
  const program = values.program as keyof QuarterlyUnitLimits;
  //if (!program) {
  //  // program is not yet assigned, on first page load
  //  return object_return; // Return an empty object and exit the function
  //}
  ///////
  // the below logic takes into account the quarter and total unit requirements rules

  const quarterlyUnitLimitsForProgram = quarterlyUnitLimits[program];
  let cumulativeUnitsRequired =
    quarterlyUnitLimitsForProgram["cumulativeUnits"];

  if (
    program === "joint_ma_mba" &&
    values.year_quarter_setup_original[0]["year"] === "2023-2024"
  ) {
    cumulativeUnitsRequired = 35; // old default
  }

  const total_units = values.total_units;

  if (total_units < cumulativeUnitsRequired) {
    more_validation_messages.push(
      `The required total units for ${inverseProgramProgramAcronym[program]} is ${cumulativeUnitsRequired}; your proposal has ${total_units} total units.`,
    );
  }
  ///////

  const courseRows = values.rows;
  const relevantMetaForProgram = initializeMeta(values);
  // let tempcheckboxes = values.checkboxes;
  // repurpose and just fill it up non purposefully now
  // or just remove tempcheckbox code altogether, but leave in for legacy development purposes
  // original use case to checkmark true and false, now that being unused
  let tempcheckboxes = {} as any;

  for (const key in relevantMetaForProgram) {
    if (relevantMetaForProgram.hasOwnProperty(key)) {
      const metaMessageValue = relevantMetaForProgram[key]["label"];
      // put in some code reuse, refactor this out later.... for application for more rules tests
      const courseArrayNumberUnitsGrading = courseRows.flatMap(
        (row: {
          courses: {
            courseNumber: string;
            courseUnits: number;
            gradingOption: string;
          }[];
        }) =>
          row.courses.map(
            ({
              courseNumber,
              courseUnits,
              gradingOption,
            }: {
              courseNumber: string;
              courseUnits: number;
              gradingOption: string;
            }) => ({ courseNumber, courseUnits, gradingOption }),
          ),
      );

      if (
        //[RulesObject.Rule2, RulesObject.Rule3, RulesObject.Rule4].includes(
        [
          RulesObject.Rule2,
          RulesObject.Rule3,
          RulesObject.Rule4,
          RulesObject.Rule5,
        ].includes(metaMessageValue)
      ) {
        let relevantQuarterlyUnitLimitsForProgram: any = {};
        if (programDifferentiatorHasPlanAPlanB(program)) {
          const plan = values.plan;
          if (plan === "a" || plan === "b") {
          } else {
            // a plan is not initially selected.... on page load
            // validations can't be done if a and b aren't selected, the system needs to know that information
            return object_return;
          }

          relevantQuarterlyUnitLimitsForProgram = (
            quarterlyUnitLimitsForProgram as any
          )[plan];
        } else {
          relevantQuarterlyUnitLimitsForProgram =
            quarterlyUnitLimitsForProgram as any;
        }

        const rows = values.rows;
        const quarterTotalPairs = rows.map(({ quarter, total, year }: any) => ({
          quarter,
          total,
          year,
        }));

        //if (Object.keys(overrides).length === 0) {
        //  console.log("No overrides object is empty.");
        if (overrides.override_last_quarter_units === true) {
          if (overrides.program === "ice" || overrides.program === "iepa") {
            // For ICE/IEPA students with an override, let's set the minimum at 3 and maximum at 10 during Summer Quarter.
            relevantQuarterlyUnitLimitsForProgram["Summer"]["min"] = 3;
            relevantQuarterlyUnitLimitsForProgram["Summer"]["max"] = 10;
          }
        }

        const tempList = quarterTotalPairs.flatMap(
          (item: { [key: string]: number }, index: number) => {
            //console.log(`Iteration index: ${index}`);
            // if the index is over 3, then the first year has been passed
            const quarter = item.quarter;
            const total = item.total;
            const year = item.year;

            if (
              overrides.override_last_quarter_units === true &&
              overrides.program === "eds" &&
              index > 3
            ) {
              // we've entered the second year
              relevantQuarterlyUnitLimitsForProgram["Spring"]["min"] = 3;
              relevantQuarterlyUnitLimitsForProgram["Spring"]["max"] = 18;
            }
            let localMin =
              relevantQuarterlyUnitLimitsForProgram[quarter]["min"];
            let localMax =
              relevantQuarterlyUnitLimitsForProgram[quarter]["max"];

            if (total >= localMin && total <= localMax) {
              return [];
            } else {
              return [
                `${quarter} Quarter ${year} Units of ${total} is not within the required range of ${localMin} and ${localMax}`,
              ];
            }
          },
        );

        if (tempList.length > 0) {
          more_validation_messages = concat(tempList, more_validation_messages);

          if (!programDifferentiatorHasPlanAPlanB(program)) {
            tempcheckboxes[key] = { checked: false };
          }
        } else if (!programDifferentiatorHasPlanAPlanB(program)) {
          tempcheckboxes[key] = { checked: true };
        }
      }

      if (metaMessageValue === unitsLetterGraded(program)) {
        const unitsRequiredLetter = extractUnits(unitsLetterGraded(program));
        const filteredArray = courseArrayNumberUnitsGrading.filter(
          (course: {
            courseNumber: string;
            courseUnits: number;
            gradingOption: string;
          }) => course.gradingOption === "Letter",
        );

        const sumOfCourseUnitsLettered: number = filteredArray.reduce(
          (
            sum: number,
            course: {
              courseNumber: string;
              courseUnits: any;
              gradingOption: string;
            },
          ) => {
            // TODO: comebacktolater, determine why course.courseUnits is a string instead of number
            const courseUnits = parseInt(course.courseUnits, 10);
            return sum + courseUnits;
          },
          0,
        );

        //console.log(sumOfCourseUnitsLettered);
        //extract out for me an array that have "gradingOption": "Letter", the array has courseNumber, courseUnits, and gradingOption
        //console.log(unitsRequiredLetter);

        if (sumOfCourseUnitsLettered >= unitsRequiredLetter) {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
          more_validation_messages.push(
            `${unitsRequiredLetter}+ Letter graded units are required. Currently only ${sumOfCourseUnitsLettered} units meet that criteria.`,
          );
        }
      }

      if (metaMessageValue === unitsAtOrAbove200(program)) {
        const unitsAbove200Required = extractUnits(unitsAtOrAbove200(program));

        const filteredAbove200 = courseArrayNumberUnitsGrading.filter(
          (item: any) => {
            if (item.courseNumber && typeof item.courseNumber === "string") {
              const numericComponent = parseInt(
                item.courseNumber.match(/\d+/)?.[0] || "0",
                10,
              );
              return numericComponent > 200;
            }
            return false; // Skip items without a valid courseNumber
          },
        );

        const sumOfAbove200Units: number = filteredAbove200.reduce(
          (
            sum: number,
            course: {
              courseNumber: string;
              courseUnits: number;
              gradingOption: string;
            },
          ) => sum + parseInt(course.courseUnits as any, 10),
          0,
        );
        if (sumOfAbove200Units >= unitsAbove200Required) {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
          more_validation_messages.push(
            `${unitsAbove200Required}+ units at or above the 200 level is required. Currently only ${sumOfAbove200Units} units meet that criteria.`,
          );
        }
      }

      if (metaMessageValue === unitsForEDUC(program)) {
        const unitsForEducRequired = extractUnits(unitsForEDUC(program));

        const educCourses = courseArrayNumberUnitsGrading.filter(
          (item: any) =>
            item.courseNumber &&
            typeof item.courseNumber === "string" &&
            item.courseNumber.startsWith("EDUC"),
        );

        const sumOfEducCourses: number = educCourses.reduce(
          (
            sum: number,
            course: {
              courseNumber: string;
              courseUnits: number;
              gradingOption: string;
            },
          ) => sum + parseInt(course.courseUnits as any, 10),
          0,
        );
        if (sumOfEducCourses >= unitsForEducRequired) {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
          more_validation_messages.push(
            `${unitsForEducRequired}+ EDUC units are required. Currently only ${sumOfEducCourses} units meet that criteria.`,
          );
        }
      }

      if (metaMessageValue === RulesObject.Rule6) {
        const courseExists393 = courseArrayNumberUnitsGrading.some(
          (course: any) => course.courseNumber === "EDUC393",
        );
        if (courseExists393) {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
          more_validation_messages.push(
            `${RulesObject.Rule6} is required. It is not included in the courses that are listed.`,
          );
        }
      }

      //Rule7: "10 cross-listed units max",
      if (metaMessageValue === RulesObject.Rule7) {
        const unitsCrossListedMax = extractUnits(RulesObject.Rule7);

        // later try to refactor this out, and see if it applies globally to courses w and w/out crossListed
        // goal, to reuse the variable
        //const courseUnitsAndCrossListed = courseRows.flatMap(
        //  (row: {
        //    courses: {
        //      courseUnits: number;
        //      crossListed: boolean;
        //    }[];
        //  }) =>
        //    row.courses.map(
        //      ({
        //        courseUnits,
        //        crossListed,
        //      }: {
        //        courseUnits: number;
        //        crossListed: string;
        //      }) => ({ courseUnits, crossListed }),
        //    ),
        //);

        // temporary fix to get typescript to build , TODO: comebacktolater
        const courseUnitsAndCrossListed = courseRows.flatMap(
          (row: {
            courses: {
              courseUnits: number;
              crossListed: any; // Here, crossListed is defined as a boolean
            }[];
          }) =>
            row.courses.map(
              ({
                courseUnits,
                crossListed,
              }: {
                courseUnits: number;
                crossListed: any; // Here, crossListed is corrected to boolean
              }) => ({ courseUnits, crossListed }), // Convert boolean to string or not....
            ),
        );

        const sumUnitsCrossListed = courseUnitsAndCrossListed.reduce(
          (acc: number, curr: any) => {
            if (curr.crossListed) {
              return acc + parseInt(curr.courseUnits);
            }
            return acc;
          },
          0,
        );

        if (sumUnitsCrossListed > unitsCrossListedMax) {
          tempcheckboxes[key] = { checked: false };
          more_validation_messages.push(
            `${RulesObject.Rule7} is permitted. The ${sumUnitsCrossListed} units listed here exceeds that criteria.`,
          );
        } else {
          tempcheckboxes[key] = { checked: true };
        }
      }

      // Rule8: "4 independent study units max",
      if (metaMessageValue === RulesObject.Rule8) {
        const unitsINSMax = extractUnits(RulesObject.Rule8);
        // EDUC180, EDUC190, EDUC380, EDUC437, EDUC480, EDUC490
        // EDUC180, EDUC190, EDUC380, EDUC437, EDUC480, EDUC490
        const courseNumbersToMatchINS: string[] = [
          "EDUC180",
          "EDUC190",
          "EDUC380",
          "EDUC437",
          "EDUC480",
          "EDUC490",
        ];
        const filteredOnlyIncludesINScoursesForJointMaMBA =
          courseArrayNumberUnitsGrading.filter((course: any) =>
            courseNumbersToMatchINS.includes(course.courseNumber),
          );

        const sumUnitsINS = filteredOnlyIncludesINScoursesForJointMaMBA.reduce(
          (acc: number, curr: any) => {
            return acc + parseInt(curr.courseUnits);
          },
          0,
        );
        if (sumUnitsINS > unitsINSMax) {
          tempcheckboxes[key] = { checked: false };
          const courseNumbersString: string =
            courseNumbersToMatchINS.slice(0, -1).join(", ") +
            " or " +
            courseNumbersToMatchINS.slice(-1);
          more_validation_messages.push(
            `${RulesObject.Rule8} is permitted out of ${courseNumbersString}. The ${sumUnitsINS} units listed here exceeds that criteria.`,
          );
        } else {
          tempcheckboxes[key] = { checked: true };
        }
      }

      // already fulfilled elsewhere
      if (metaMessageValue === RulesObject.Rule5) {
        const plan = values.plan;
        if (plan === "a" || plan === "b") {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
        }
      }

      // nice to have rule, all courses above 100
      if (metaMessageValue === RulesObject.Rule1) {
        //console.log("you've hit rule1");
        //console.log(values.rows);
        const courseNumbers = courseRows
          .flatMap((rowItem: any) =>
            rowItem.courses.map((course: any) => {
              if (
                course.courseNumber &&
                typeof course.courseNumber === "string"
              ) {
                const match = course.courseNumber.match(/\d+/);
                if (match) {
                  return parseInt(match[0], 10);
                }
              }
              return undefined; // return undefined if there's no valid courseNumber
            }),
          )
          .filter((number: any) => number !== undefined); // remove undefined values

        const belowSomeValue100 = courseNumbers.some(
          (courseNumber: any) => courseNumber < 100,
        );
        if (belowSomeValue100) {
          tempcheckboxes[key] = { checked: false };
          //setFieldValue("checkboxes", tempcheckboxes);
        } else {
          tempcheckboxes[key] = { checked: true };
        }
      }

      // already fulfilled elsewhere...can centralize it here though...
      if (metaMessageValue === unitsForProgramType(program, values)) {
        const unitsForProgramRequired = extractUnits(
          unitsForProgramType(program, values),
        );
        const total_units = values.total_units;
        //console.log(total_units);

        if (total_units >= unitsForProgramRequired) {
          tempcheckboxes[key] = { checked: true };
        } else {
          tempcheckboxes[key] = { checked: false };
        }
      }
    }
  }
  object_return["more_validation_messages"] = more_validation_messages;
  object_return["tempcheckboxes"] = tempcheckboxes;
  return object_return;
  //return more_validation_messages;
};

export const handleSignIn = () => {
  if (baseEmailDomain === "stanford.edu") {
    googleProvider.setCustomParameters({
      hd: "stanford.edu",
    });
  }

  // Use redirect for prod, popup for dev/emulator
  if (
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
  ) {
    signInWithRedirect(auth, googleProvider);
  } else {
    signInWithPopup(auth, googleProvider);
  }
};

export const fetchAndPostFirebasePlusDefaults = async (userData: any) => {
  try {
    const sunet = userData.username;
    const displayName = userData.displayName;

    let data: any = {};
    data["defaultYear"] = await getConstantValues("defaultYear");
    data["defaultQuarter"] = await getConstantValues("defaultQuarter");
    data["uid"] = sunet;
    data["displayName"] = displayName;
    //console.log("data");
    //console.log(data);

    const fetchDataFirestore = httpsCallable(
      functions,
      //"firebase_establish_account_by_api_db",
      "firebase_establish_account_by_api_db_firestore",
    );
    fetchDataFirestore(data);
  } catch (error) {
    console.log(error);
  }
};

export const handleProcessFiles = async (
  files: any,
  sunetEmailAddress: string,
  setUploading: any,
  onClose: any,
  smartsheetResponse: any,
  setAttachmentProcessing: any,
  setShouldPageRefreshContent: any,
) => {
  const smartsheetResponseReverse = smartsheetResponse.slice().reverse();
  const mostRecentSubmission = smartsheetResponseReverse[0];

  setUploading(true);
  const urls = [];
  const names = [];

  for (const file of files) {
    const fileName = file.name;
    const storageRef = ref(storage, `${sunetEmailAddress}/${fileName}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      urls.push(downloadURL);
      names.push(fileName);
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error);
    }
  }
  try {
    const smartsheet_update_function = httpsCallable(
      functions,
      "smartsheet_data_update_smartsheet_row_with_attachment",
    );
    const result = await smartsheet_update_function({
      fileUrls: urls,
      fileNames: names,
      mostRecentSubmission: mostRecentSubmission,
      sunetEmailAddress: sunetEmailAddress,
    });
    console.log("Backend response:", result.data);
    const response = result.data as any;

    if (response.status !== "error") {
      onClose();
      // window.location.reload();
      setShouldPageRefreshContent(true);
      setTimeout(() => setShouldPageRefreshContent(false), 500); // shortly thereafter provide a reset
    }
  } catch (error) {
    console.error("Error calling function:", error);
  } finally {
    setUploading(false); // disable the upload button until all is done, firebase storage AND smartsheet upload
    setAttachmentProcessing(false); // turn off the spinner
  }
};

export const determineBeyondExploreCourses = (
  currentQuarterIndex: number,
  courseAccessLastQuarterYearIndex: number,
) => {
  //console.log("currentQuarterIndex");
  //console.log(currentQuarterIndex);
  //console.log(courseAccessLastQuarterYearIndex);
  //console.log(currentQuarterIndex > courseAccessLastQuarterYearIndex);
  return currentQuarterIndex > courseAccessLastQuarterYearIndex;
};

export const updateInitialValues = (
  setInitialValues: any,
  setFormikKey: any,
  all_rows: any,
  year_quarter_setup: any,
  year_quarter_setup_original: any,
  is_unique_schedule: any,
) => {
  setInitialValues((prevInitialValues: any) => ({
    ...prevInitialValues,
    rows: all_rows,
    year_quarter_setup: year_quarter_setup,
    year_quarter_setup_original: year_quarter_setup_original,
    is_unique_schedule: is_unique_schedule,
  }));
  setFormikKey((prevKey: number) => prevKey + 1);
};

export const getApprovalValues = (obj: any) => {
  const {
    officer_email_approval,
    advisor_email_approval,
    director_email_approval,
  } = obj;
  const approvalValues = [
    officer_email_approval,
    advisor_email_approval,
    director_email_approval,
  ];

  return approvalValues.filter((value) => value !== null);
};

const adjustYearRange = (range: string, yearsPrior: number) => {
  const [startYear, endYear] = range.split("-").map(Number);
  return `${startYear - yearsPrior}-${endYear - yearsPrior}`;
};

export const getProgramVanityName = (program: string): string => {
  if (["joint_ma_mba"].includes(program)) {
    return "MA/MBA";
  } else if (["designed"].includes(program)) {
    return "IND. DESIGNED";
  } else {
    return program.toUpperCase();
  }
};

export const handleCourseTitleUpdateManuallyObject = (code: string) => {
  let cannedPrefix = "";
  let toastWarning = "";
  if (code === "215") {
    cannedPrefix = default215text;
    toastWarning = `In the course title field, please enter your internship location: "${cannedPrefix}Your Organization Name"`;
  } else if (code === "180") {
    cannedPrefix = default180text;
    toastWarning = `In the course title field, indicate an instructor: "${cannedPrefix}Your Professor Name"`;
  } else if (code === "190") {
    cannedPrefix = default190text;
    toastWarning = `In the course title field, indicate an instructor: "${cannedPrefix}Your Professor Name"`;
  }
  const someObject = {
    cannedPrefix: cannedPrefix,
    toastWarning: toastWarning,
  };
  return someObject;
};

export const handleCourseTitleUpdateManually = (
  code: string,
  subject: string,
  rowIndex: number,
  courseIndex: number,
  values: any,
  setFieldValue: (field: string, value: any) => void,
) => {
  const relevantCodes = ["215", "180", "190"];
  const objectyo = handleCourseTitleUpdateManuallyObject(code);
  const cannedPrefix = objectyo.cannedPrefix;
  const toastWarning = objectyo.toastWarning;

  if (subject === "EDUC" && relevantCodes.includes(code)) {
    const currentCourseTitle =
      values.rows[rowIndex].courses[courseIndex].courseTitle;

    if (currentCourseTitle === "") {
      setFieldValue(
        `rows[${rowIndex}].courses[${courseIndex}].courseTitle`,
        cannedPrefix,
      );
    }

    if (currentCourseTitle === "" || currentCourseTitle === cannedPrefix) {
      const courseTitleInput = document.querySelector(
        `input[name="rows[${rowIndex}].courses[${courseIndex}].courseTitle"]`,
      ) as HTMLInputElement;

      if (courseTitleInput) {
        courseTitleInput.focus();
        //courseTitleInput.select(); // Optionally highlight the text
      }

      toast.warning(toastWarning);
    }
  }
};

export const fetchOverrides = async (
  email_address: string,
  setSmartsheetOverridesProgramLastQuarterRelevantIndividual: any,
) => {
  //console.log("Fetching overrides...");
  try {
    const overrides = await getOverrides(email_address);
    if (overrides) {
      //console.log("Overrides:", overrides);
      //console.log(
      //  "Override Last Quarter Units:",
      //  overrides.override_last_quarter_units,
      //);
      //console.log("Program:", overrides.program);

      setSmartsheetOverridesProgramLastQuarterRelevantIndividual({
        program: overrides.program,
        override_last_quarter_units: overrides.override_last_quarter_units,
      });
    } else {
      //console.log("No overrides found for the provided email.");
      setSmartsheetOverridesProgramLastQuarterRelevantIndividual({
        program: null,
        override_last_quarter_units: null,
      });
    }
  } catch (error) {
    console.error("Error fetching constant values:", error);
  }
};
