import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { SessionProvider } from "./components/SessionContext";

import Landing from "./pages/Landing";
import Status from "./pages/Status";
import About from "./pages/About";
import IntakeForm from "./pages/IntakeForm";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import MetaProgram from "./components/MetaProgram";
import { intakeFormLoader } from "./extras/loaders";
import { getConstantValues } from "./extras/auxiliary_functions";

import "./stylesheets/index.css";
import "./stylesheets/App.css";

const programs = [
  "pols",
  "ldt",
  "hcp",
  "mpp",
  "eds",
  "ice",
  "iepa",
  "joint_ma_mba",
  "designed",
];

const AppRouter = () => {
  const [constantValuesDefaultYear, setConstantValuesDefaultYear] = useState<{
    startingYearOriginal: string | null;
  } | null>(null);

  useEffect(() => {
    // Initialize app with default year
    const fetchConstantValuesDefaultYear = async () => {
      try {
        const startingYearOriginal = await getConstantValues("defaultYear");
        setConstantValuesDefaultYear({ startingYearOriginal });
      } catch (error) {
        console.error("Error fetching constant values:", error);
      }
    };

    fetchConstantValuesDefaultYear();
  }, []);

  //if (!constantValuesDefaultYear) {
  //  return <div>Loading...</div>; // Show a loading state while fetching the values
  //}

  const routes = createRoutesFromElements(
    <>
      <Route path="/" element={<Landing />} />
      <Route path="/status" element={<Status />} />
      <Route path="/asdfqwerasdfqwerabout" element={<About />} />
      <Route path="/form" element={<IntakeForm />} loader={intakeFormLoader} />
      <Route path="/login" element={<Login />} />
      {programs.map((program) => {
        const values = {
          program,
          ...constantValuesDefaultYear,
        };
        return (
          <Route
            key={program}
            path={`/meta/${program}`}
            element={<MetaProgram values={values} />}
          />
        );
      })}
      <Route path="*" element={<NoPage />} />
    </>,
  );

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <SessionProvider>
    <AppRouter />
  </SessionProvider>,
);
