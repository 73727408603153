import { SessionContext } from "../components/SessionContext";

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";
import { handleSignIn } from "../extras/auxiliary_functions";

const MastersProgramProposal: React.FC<{
  userData: any;
  handleSignIn: any;
}> = ({ userData, handleSignIn }) => {
  return (
    <div className="centered-container mt-20 mb-20 grow w-full">
      <p className="mb-4 intro-text">
        The GSE Program Proposal is a University-required document wherein
        Master’s students plan, in consultation with their advisor(s), their
        course of study.
      </p>

      <ul className="ml-20">
        <li>
          Students must complete two program proposals, a preliminary version
          and a final version, by the specified deadlines.
        </li>
        <li>
          Students must list every course they will take in fulfillment of their
          program’s degree requirements.
        </li>
        <li>
          Students must consult the{" "}
          <a
            href="https://ed.stanford.edu/academics/masters-handbook/program-requirements"
            className="text-blue-500"
          >
            Master’s Handbook
          </a>{" "}
          to ensure that their course of study fulfills their program
          requirements.
        </li>
      </ul>

      <p className="mt-25 mb-10">
        All GSE Master’s students must enter their proposed coursework and
        obtain approvals from the following:
      </p>

      <ol className="list-decimal ml-20 mb-4">
        <li>the GSE Program Officer</li>
        <li>their Program Director</li>
        <li>
          their Faculty Advisor (ICE, IEPA, LDT, Individually-Designed Master’s
          students only)
        </li>
      </ol>

      <p className="mt-25 mb-4">
        Failure to submit the form by the specified deadline or obtain all the
        necessary approvals may result in an enrollment hold and/or a delay in
        degree conferral.
      </p>

      {userData.username ? (
        <>
          <Link to="/form">
            <button className="button my-16" type="button">
              Start My Proposal
            </button>
          </Link>
          <Link to="/status">
            <button className="button my-16 ml-6" type="button">
              Check Proposal Status
            </button>
          </Link>
        </>
      ) : (
        <button onClick={handleSignIn} className="button my-16" type="button">
          Stanford Login
        </button>
      )}
    </div>
  );
};

const Landing = () => {
  //const { userData } = useContext(SessionContext);
  //console.log("userData");
  //console.log(userData);

  const { userData } = useContext(SessionContext);

  return (
    <div className="flex flex-col w-full min-h-screen">
      <HeaderStanford />
      <MastersProgramProposal
        userData={userData}
        handleSignIn={() => handleSignIn()}
      />
      <FooterStanford />
    </div>
  );
};

export default Landing;
