// import { useEffect } from "react";
//import { useState, useEffect } from "react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import AboutSiteGrid from "../components/AboutSiteGrid";
import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";
import SiteGridExample from "../components/SiteGridExample";

import { getConstantValues } from "../extras/auxiliary_functions";

import { functions } from "../../firebase.config.js";
import { SessionContext } from "../components/SessionContext";
import { httpsCallable } from "firebase/functions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  //const [userDisplayName, setUserDisplayName] = useState("");
  //const [userEmail, setUserEmail] = useState("");

  const handleButtonClick = async () => {
    //const fetchData = httpsCallable(functions, 'fetch_data_from_user_token');
    //const fetchData = httpsCallable(functions, "sunet_basic_information");
    //const fetchData = httpsCallable(functions, "sunet_graduate_quarters");
    const stuff = await getConstantValues("defaultYear");
    console.log(stuff);

    const fetchData = httpsCallable(
      functions,
      //"pdfgen_example_2",
      //"pdfgen_example",
      //"smartsheet_round_configuration_222",
      //"firebase_round_configuration_sync",
      "firebase_constant_values_sync",
    );
    fetchData({ text: "testing!" }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      console.log(data);
    });
  };
  const handleButtonClick2 = async () => {
    //const fetchData = httpsCallable(functions, 'fetch_data_from_user_token');
    //const fetchData = httpsCallable(functions, "sunet_basic_information");
    //const fetchData = httpsCallable(functions, "sunet_graduate_quarters");

    const fetchData = httpsCallable(
      functions,
      //"pdfgen_example",
      "firebase_round_configuration_sync",
    );
    fetchData({ text: "testing!" }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      console.log(data);
    });
  };

  const handleButtonClick3 = async () => {
    const fetchData = httpsCallable(functions, "firebase_form_submission_sync");
    fetchData({ text: "testing!" }).then((result) => {
      // Read result of the Cloud Function.
      const data = result.data;
      console.log(data);
    });
  };

  const handleButtonClick5 = async () => {
    const fetchData = httpsCallable(
      functions,
      "firebase_smartsheet_override_program_last_quarter_sync",
    );
    fetchData({ text: "testing!" }).then((result) => {
      // Read result of the Cloud Function.
      const data = result.data;
      console.log(data);
    });
  };

  //const handleButtonClick2 = (userData: any) => {
  //  //const fetchData = httpsCallable(functions, "firebase_save_form_data");
  //  console.log("userData");
  //  console.log(userData);
  //  const student_email = userData.email;
  //  if (student_email) {
  //    const displayName = userData.displayName;
  //    const fetchData = httpsCallable(functions, "firebase_save_form_data");
  //    fetchData({
  //      student_email: student_email,
  //      program: "pols",
  //      displayName: displayName,
  //    })
  //      .then((result) => {
  //        const data = result.data;
  //        console.log(data);
  //      })
  //      .catch((error) => {
  //        console.error("Error:", error);
  //      });
  //  } else {
  //    console.error("User email not found");
  //  }
  //};

  const { userData } = useContext(SessionContext);

  const [inputValue, setInputValue] = useState(5);

  const handleIncrement = () => {
    setInputValue(inputValue + 1);
  };

  const handleDecrement = () => {
    setInputValue(inputValue - 1);
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <HeaderStanford />
      <div>
        {userData.displayName && <p>Display Name: {userData.displayName}</p>}
        {userData.email && <p>Email: {userData.email}</p>}
        {userData.username && <p>Username: {userData.username}</p>}
      </div>
      <div>
        <button className="button" onClick={handleButtonClick}>
          Test handleButtonClick, constant values
        </button>
      </div>
      <p>hello</p>
      <div>
        <button className="button" onClick={handleButtonClick2}>
          Test handleButtonClick2, firebase round configuration sync
        </button>
      </div>
      <p>bye</p>
      <div>
        <button className="button" onClick={handleButtonClick3}>
          Test handleButtonClick3, firebase form submission sync
        </button>
      </div>
      <p>hello</p>
      <div>
        <button className="button" onClick={handleButtonClick5}>
          Test handleButtonClick5,
          firebase_smartsheet_override_program_last_quarter_sync
        </button>
      </div>
      <p>bonjour</p>

      <div className="">
        <div className="relative w-180">
          <button
            type="button"
            className="absolute left-0 top-0 rounded-r-none btn btn-square px-10 pt-8 border-r-2"
            onClick={handleDecrement}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input
            type="text"
            className="w-full text-center input"
            value={inputValue}
            onChange={handleChange}
          />
          <button
            type="button"
            className="absolute right-0 top-0 rounded-l-none btn btn-square px-10 pt-8 border-l-2"
            onClick={handleIncrement}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>

      {/* <button onClick={() => handleButtonClick2(userData)}>
          Test Post handleButtonClick2
          </button> */}
      <AboutSiteGrid />
      {/* <SiteGridExample /> */}
      {/* <span className="text-gray-700">Formik Land</span> */}
      {/* https://dev.to/hi_iam_chris/react-forms-formik-and-yup-intro-154g */}
      {/* https://stackoverflow.com/questions/56292392/i-cant-send-post-request-from-react-form-to-flask-server */}
      {/* https://sanjeevan.co.uk/blog/flask-marshmallow-and-formik/ */}
      <div className="cc flex flex-col md:flex-row rs-py-2 overflow-x-hidden">
        <div className="mx-auto w-full">
          <article id="text__paragraphs" className="rs-mb-5">
            <p>
              There are four main pages of this site, and of course this
              unlisted About page too
            </p>
            <ul>
              <li>
                <Link to="/">Home</Link>{" "}
              </li>
              <li>
                <Link to="/form">Form</Link>{" "}
              </li>
              <li>
                <Link to="/status">Status</Link>{" "}
              </li>
              <li>
                <Link to="/login">Login</Link>{" "}
              </li>
              <li>
                <Link to="/about">About</Link>{" "}
              </li>
            </ul>
          </article>
        </div>
      </div>
      <SiteGridExample />
      <FooterStanford />
    </div>
  );
};

export default About;
